<!-- 选择产品 -->
<template>
  <!-- 续费产品 -->
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
  </div>
</template>

<script>
import TablePage from "@/components/tablePage/index.vue";
import {
  listDouYinProduct,
  uploadDouYinProduct,
  productDouYinOperate,
  productDouYinDelete,
  douYinProductRestore, //还原
  stockSyncDouYinProduct,
  getOnlineProduct,
} from "@/api/O2OThird/douyin/coupon";
export default {
  name: "Coupon",
  dicts: ["douyin_category"],
  components: { TablePage },
  data() {
    return {
      options: {
        tableTitle: true,
        listNo: true,
        mutiSelect: true,
        loading: true,
        check: [],
        title: "抖音券",
        tabColumnType: "listing",
        rowKey: "productId",
        search: [
          {
            type: "filters",
            isRestore: true,
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "productNos", label: "劵方案编号" },
              { filter: "productNames", label: "劵方案名称" },
            ],
          },
          // {
          //   type: 'radio',
          //   label: '是否上架',
          //   filter: 'isDelisting',
          //   model: '',
          //   option: {
          //     data: [
          //       {
          //         label: '全部',
          //         value: ''
          //       },
          //       {
          //         label: '是',
          //         value: true
          //       },
          //       {
          //         label: '否',
          //         value: false
          //       }
          //     ]
          //   }
          // },
          // {
          //   type: 'radio',
          //   label: '是否上传',
          //   filter: 'isUpload',
          //   model: '',
          //   option: {
          //     data: [
          //       {
          //         label: '全部',
          //         value: ''
          //       },
          //       {
          //         label: '是',
          //         value: true
          //       },
          //       {
          //         label: '否',
          //         value: false
          //       }
          //     ]
          //   }
          // },
          {
            type: "button",
            tip: "查询",
            btnType: "primary",
            click: "search",
            isRestore: true,
          },
          // {
          //   type: 'button',
          //   tip: '高级查询',
          //   btnType: 'primary',
          //   click: 'seniorSearch'
          // },
          { type: "button", tip: "重置", click: "reset" },
        ],
        tabsColumns: [
          {
            title: "已上架商品",
            rowKey: "productId",
            getListApi: listDouYinProduct,
            uuid: "854ce3b5-81b3-fbbc-3dae-640d83192b96",
            defaultBody: { isDelisting: true },
            type: "listing",
            isHideRestore: true,
            buttons: [
              {
                click: "add",
                label: "新增",
                type: "primary",
              },
              {
                click: "uploadProgramme",
                label: "上传券方案",
                type: "primary",
                alertText: "确认要上传选中的券方案吗？",
              },
              {
                click: "inventory",
                label: "同步库存",
                type: "primary",
                alertText: "确认要同步选中的券方案库存吗？",
              },
              {
                click: "unmount",
                label: "券方案下架",
                type: "primary",
                alertText: "确认要下架选中的券方案吗？",
              },
              {
                click: "viewStatus",
                label: "查询状态",
                type: "primary",
                // alertText: '确认要查询选中的券方案状态吗？'
              },
              {
                click: "refresh",
                right: true,
                label: "刷新",
                icon: "el-icon-refresh",
                type: "",
              },
            ],
            columns: [
              {
                type: "link",
                click: "update",
                prop: "productNo",
                label: "券方案编号",
                minWidth: 150,
              },
              { prop: "productName", label: "券方案名称", minWidth: 150 },
              { prop: "productId", label: "券ID", minWidth: 180 },
              { prop: "douyinProductId", label: "抖音商品ID", minWidth: 180 },
              {
                prop: "categoryId",
                label: "抖音品类",
                minWidth: 150,
                type: "dict",
                dict: "douyin_category",
              },
              {
                prop: "productTypeName",
                label: "商品类型",
                minWidth: 150,
              },
              {
                prop: "productSourceTypeName",
                label: "商品来源",
                minWidth: 120,
              },
              {
                prop: "isUpload",
                label: "是否上传",
                minWidth: 155,
                formatter: (v) => (v ? "是" : "否"),
              },
              {
                prop: "isDelisting",
                label: "是否上架",
                minWidth: 155,
                formatter: (v) => (v ? "是" : "否"),
              },
              { prop: "updateBy", label: "修改人", minWidth: 150 },
              { prop: "updateTime", label: "修改时间", minWidth: 155 },
              { prop: "createBy", label: "创建人", minWidth: 150 },
              { prop: "createTime", label: "创建时间", minWidth: 155 },
            ],
          },
          {
            title: "未上架商品",
            rowKey: "productId",
            getListApi: listDouYinProduct,
            uuid: "de90c639-f529-8f66-61d4-0461efd8fe37",
            defaultBody: { isDelisting: false },
            type: "delist",
            buttons: [
              {
                click: "add",
                label: "新增",
                type: "primary",
              },
              {
                click: "uploadProgramme",
                label: "上传券方案",
                type: "primary",
                alertText: "确认要上传选中的券方案吗？",
              },
              {
                click: "unmount",
                label: "券方案上架",
                type: "primary",
                alertText: "确认要上架选中的券方案吗？",
              },
              {
                click: "viewStatus",
                label: "查询状态",
                type: "primary",
              },
              {
                click: "del",
                label: "删除",
                type: "danger",
                icon: "el-icon-delete",
                alertText: "确认要删除选中的抖音券吗？",
                btnType: "dropdown",
                trigger: "click", // 要求
                other: [
                  {
                    label: "回收站",
                    collectionDialog: {
                      restoreListApi: douYinProductRestore,
                      dcollectionBody: {
                        delFlag: true,
                      },
                    },
                  },
                ],
              },
              {
                click: "refresh",
                right: true,
                label: "刷新",
                icon: "el-icon-refresh",
                type: "",
              },
            ],
            columns: [
              {
                type: "link",
                click: "update",
                prop: "productNo",
                label: "券方案编号",
                minWidth: 150,
              },
              { prop: "productName", label: "券方案名称", minWidth: 150 },
              { prop: "productId", label: "券ID", minWidth: 180 },
              { prop: "douyinProductId", label: "抖音商品ID", minWidth: 180 },
              {
                prop: "categoryId",
                label: "抖音品类",
                minWidth: 150,
                type: "dict",
                dict: "douyin_category",
              },
              {
                prop: "productTypeName",
                label: "商品类型",
                minWidth: 150,
              },
              {
                prop: "productSourceTypeName",
                label: "商品来源",
                minWidth: 120,
              },
              {
                prop: "isUpload",
                label: "是否上传",
                minWidth: 155,
                formatter: (v) => (v ? "是" : "否"),
              },
              {
                prop: "isDelisting",
                label: "是否上架",
                minWidth: 155,
                formatter: (v) => (v ? "是" : "否"),
              },
              { prop: "updateBy", label: "修改人", minWidth: 150 },
              { prop: "updateTime", label: "修改时间", minWidth: 155 },
              { prop: "createBy", label: "创建人", minWidth: 150 },
              { prop: "createTime", label: "创建时间", minWidth: 155 },
            ],
          },
        ],
        list: [],
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case "columnsChange":
          // this.options.buttons[2].label =
          //   this.options?.tabColumnType == 'listing'
          //     ? '券方案下架'
          //     : '券方案上架'
          break;
        case "dialogChange":
          this.$refs.tablePage.getList();
          break;
        case "add":
          //跳转商品明细页
          const timestampId = Date.now();
          this.$router.push({
            path: "/O2OThird/douyin/coupon/couponDetail",
            query: {
              type: "add",
              timestampId,
            },
          });
          break;
        case "uploadProgramme":
          try {
            this.options.loading = true;
            await uploadDouYinProduct(this.options.check.map((v) => v.productId));
            await this.$refs.tablePage.handleEvent("getList");
            this.$message.success("上传成功");
          } catch (error) {
            this.options.loading = false;
          }

          break;
        case "unmount":
          try {
            let isUpload = this.options.check.every(
              (item) => item.isUpload || item.productSourceType == 2
            );
            if (!isUpload) {
              this.$message.error("请上传券方案后再上架！");
              return;
            }
            this.options.loading = true;
            await productDouYinOperate({
              opType: this.options.tabColumnType == "listing" ? 2 : 1,
              productIds: this.options.check.map((v) => v.productId),
            });
            await this.$refs.tablePage.handleEvent("getList");
            this.$message.success(
              this.options.tabColumnType == "listing" ? "下架成功" : "上架成功"
            );
          } catch (error) {
            this.options.loading = false;
          }
          break;
        case "del":
          try {
            this.options.loading = true;
            await productDouYinDelete(this.options.check.map((v) => v.productId));
            await this.$refs.tablePage.handleEvent("getList");
            this.$message.success("删除成功");
          } catch (error) {
            this.options.loading = false;
          }
          break;
        case "update":
          this.$router.push({
            path: "/O2OThird/douyin/coupon/couponDetail",
            query: {
              type: "Update",
              productId: row.productId,
            },
          });
          break;
        case "inventory":
          try {
            this.options.loading = true;
            await stockSyncDouYinProduct(
              this.options.check.map((v) => {
                return {
                  productId: v.productId,
                  skuId: v.skuId,
                };
              })
            );
            await this.$refs.tablePage.handleEvent("getList");
            this.$message.success("同步成功");
          } catch (error) {
            this.options.loading = false;
          }
          break;
        case "viewStatus":
          try {
            this.options.loading = true;
            await getOnlineProduct(this.options.check.map((v) => v.productId));
            await this.$refs.tablePage.handleEvent("getList");
            this.$message.success("查询成功");
          } catch (error) {
            this.options.loading = false;
          }
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style scoped lang="scss"></style>
