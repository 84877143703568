<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent"> </TablePage>
    <category
      :isSubmit="isSubmit"
      :check="checkList"
      :openCategory.sync="openCategory"
      :isCake="true"
      @openCategory="getPitchData($event)"
    />
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import category from "@/views/O2OMall/goods/components/category"; // 选择分类
import {
  cakefreeselectGoodsList,
  listOrdermealGoods,
  cakefreeselectGoodsBadge,
  cakefreeselectCategoryLiStTree,
  cakefreeselectSaveCategory,
  cakefreeselectCategoryUpdate,
  cakefreeselectGetDetailCategory,
  cakefreeselectRemoveCategory,
  cakefreeselectBatchLaunch,
  synAllImageBasisGoodsToMall,
} from "@/api/O2OThird/cakeFreeSelectApp/goods";
import { listCategoryTree } from "@/api/goods/category";
export default {
  name: "List",
  components: { TablePage, category },
  data() {
    return {
      isSubmit: false,
      checkList: [],
      openCategory: false,
      options: {
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        body: {},
        title: "商品列表",
        mutiSelect: true, // 多选
        check: [], // 选中数据
        labelWidth: "80",
        search: [
          {
            type: "input",
            tip: "商品编号/商品名称",
            model: "",
            filter: "query",
          },
          {
            type: "button",
            tip: "同步商品基础资料图片",
            btnType: "primary",
            click: "synchronizeProducts",
          },
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          { type: "button", tip: "重置", click: "reset" },
        ],
        rowKey: "goodsId",
        tabsColumns: [
          {
            title: "已上架商品",
            rowKey: "goodsId",
            getListApi: cakefreeselectGoodsList,
            uuid: "175a6c0c-6d42-9ca5-1be2-ae415a8a7c46",
            defaultBody: { status: 0, goodsType: 1 },
            type: "partner",
            tableTree: {
              defaultExpandAll: true,
              dialogOptions: {
                api: {
                  add: cakefreeselectSaveCategory,
                  update: cakefreeselectCategoryUpdate,
                  detail: cakefreeselectGetDetailCategory,
                  remove: cakefreeselectRemoveCategory,
                },
                data: {
                  type: "cake",
                },
                title: "商品分类",
                type: "O2OTreeGoodsCategory",
              },
              title: "分类",
              value: "id",
              label: "label",
              setData: (e) => ({ categoryId: e.id || "" }), // 后端要求全部分类为空,
              getListApi: cakefreeselectCategoryLiStTree,
            },
            columns: [
              {
                prop: "goodsNo",
                label: "商品编码",
                type: "link",
                click: "routerLink",
                minWidth: 180,
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 150,
              },
              {
                type: "image",
                prop: "goodsUrl",
                label: "图片",
                minWidth: 150,
              },
              {
                prop: "sortNo",
                label: "排序",
                minWidth: 150,
              },
              {
                prop: "categoryName",
                label: "分类",
                minWidth: 150,
              },
              {
                prop: "goodsSpec",
                label: "商品规格",
                minWidth: 150,
              },
              {
                prop: "unitName",
                label: "商品单位",
                minWidth: 150,
              },
              {
                prop: "status",
                label: "上架状态",
                minWidth: 100,
                formatter: (v) => (v == 1 ? "未上架" : "已上架"),
              },
            ],
            buttons: [
              {
                click: "below",
                label: "下架",
                type: "danger",
                // icon: "el-icon-sort-down",
                icon: "el-icon-sort-down",
                disabled: () => !this.options?.check?.length,
              },
              {
                click: "modify",
                label: "批量修改",
                type: "primary",
                // icon: "el-icon-sort-down",
                icon: "el-icon-sort-down",
                disabled: () => !this.options?.check?.length,
              },
            ],
          },
          {
            title: "未上架商品",
            rowKey: "goodsId",
            getListApi: cakefreeselectGoodsList,
            uuid: "5b59e7e7-9b0e-9399-3743-710794db321a",
            defaultBody: { status: 1, goodsType: 1 },
            type: "partnerStore",
            tableTree: {
              //
              defaultExpandAll: true,
              dialogOptions: {
                api: {
                  add: cakefreeselectSaveCategory,
                  update: cakefreeselectCategoryUpdate,
                  detail: cakefreeselectGetDetailCategory,
                  remove: cakefreeselectRemoveCategory,
                },
                data: {
                  type: "cake",
                },
                title: "商品分类",
                type: "O2OTreeGoodsCategory",
              },
              title: "分类",
              value: "id",
              label: "label",
              setData: (e) => ({ categoryId: e.id || "" }), // 后端要求全部分类为空,
              getListApi: cakefreeselectCategoryLiStTree,
            },
            columns: [
              {
                prop: "goodsNo",
                label: "商品编码",
                type: "link",
                click: "routerLink",
                minWidth: 180,
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 150,
              },
              {
                type: "image",
                prop: "goodsUrl",
                label: "图片",
                minWidth: 150,
              },
              {
                prop: "sortNo",
                label: "排序",
                minWidth: 150,
              },
              {
                prop: "categoryName",
                label: "分类",
                minWidth: 150,
              },
              {
                prop: "goodsSpec",
                label: "商品规格",
                minWidth: 150,
              },
              {
                prop: "unitName",
                label: "商品单位",
                minWidth: 150,
              },
              {
                prop: "status",
                label: "上架状态",
                minWidth: 100,
                formatter: (v) => (v == 1 ? "未上架" : "已上架"),
              },
            ],
            buttons: [
              {
                click: "upper",
                label: "上架",
                type: "success",
                // icon: "el-icon-top",
                icon: "el-icon-sort-up",
                disabled: () => !this.options?.check?.length,
              },
              {
                click: "modify",
                label: "批量修改",
                type: "primary",
                // icon: "el-icon-sort-down",
                icon: "el-icon-sort-down",
                disabled: () => !this.options?.check?.length,
              },
            ],
          },
          {
            title: "商品",
            rowKey: "goodsId",
            getListApi: listOrdermealGoods,
            uuid: "89b81c28-a179-9f5e-6f37-5ec2902e6152",
            defaultBody: { status: 0, goodsType: 1 },
            type: "partnerGoodsCategory",
            tableTree: {
              defaultExpandAll: true,
              title: "分类",
              value: "id",
              label: "label",
              setData: (e) => {
                return { categoryId: e.id };
              },
              getListApi: listCategoryTree,
            },
            columns: [
              // {
              //   prop: "goodsNo",
              //   label: "商品编码",
              //   type: "link",
              //   click: "routerLink",
              //   minWidth: 180,
              // },
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 150,
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 150,
              },
              {
                type: "image",
                prop: "goodsUrl",
                label: "图片",
                minWidth: 150,
              },
              // {
              //   prop: 'sortNo',
              //   label: '排序',
              //   minWidth: 150
              // },
              {
                prop: "categoryName",
                label: "分类",
                minWidth: 150,
              },
              {
                prop: "goodsSpec",
                label: "商品规格",
                minWidth: 150,
              },
              {
                prop: "unitName",
                label: "商品单位",
                minWidth: 150,
              },
              {
                prop: "status",
                label: "上架状态",
                minWidth: 100,
                formatter: (v) => (v == 1 ? "未上架" : "已上架"),
              },
            ],
            buttons: [
              {
                click: "submit",
                label: "保存",
                type: "primary",
                disabled: () => !this.options?.check?.length,
              },
            ],
          },
        ],
      },
    };
  },
  methods: {
    getPitchData() {
      this.$refs.tablePage.getList();
      this.getGoodsBadge();
      this.openCategory = false;
      this.options.check = [];
    },
    async getGoodsBadge() {
      const res = await cakefreeselectGoodsBadge();
      this.options.tabsColumns[0].title = `已上架商品(${res.data.onlineQty || 0})`;
      this.options.tabsColumns[1].title = `未上架商品(${res.data.offLineQty || 0})`;
      this.options.tabsColumns[2].title = `商品(${res.data.unSyncQty || 0})`;
    },
    batchLaunchFun(goodsIds, status) {
      this.$modal.confirm(`是否确认${status == 1 ? "下架" : "上架"}`).then(async () => {
        const res = await cakefreeselectBatchLaunch({
          goodsIds: [...goodsIds],
          status: status,
        });
        await this.$nextTick();
        this.options.check = [];
        this.$refs.tablePage.getList();
        this.$modal.msgSuccess(
          `${status == 1 ? "下架" : "上架"}成功，请到已上架商品查看`
        );
        this.getGoodsBadge();
      });
    },
    async handleEvent(type, row) {
      let goodsId = [];
      switch (type) {
        case "synchronizeProducts":
          // this.getGoodsBadge();
          this.$modal.confirm('是否同步"基本资料—商品资料"的图片').then(async () => {
            synAllImageBasisGoodsToMall()
              .then((res) => {
                this.$message.success("同步成功");
              })
              .catch((err) => {
                this.$message.error("同步失败");
              });
          });
          break;
        case "columnsChange":
          this.getGoodsBadge();
          break;
        case "routerLink":
          {
            this.$router.push({
              path: "/O2OThird/cakeFreeSelectApp/goods/editGoods/index",
              query: {
                type: "cake",
                goodId: row.goodsId,
              },
            });
          }
          break;
        case "below":
          if (!this.options.check.length) return;
          goodsId = this.options.check.map((i) => i.goodsId);
          this.batchLaunchFun(goodsId, 1);
          break;
        case "upper":
          if (!this.options.check.length) return;
          goodsId = this.options.check.map((i) => i.goodsId);
          this.batchLaunchFun(goodsId, 0);
          break;
        case "modify":
          goodsId = this.options.check.map((i) => i.goodsId);
          this.checkList = goodsId;
          this.isSubmit = false;
          this.openCategory = true;
          break;
        case "submit":
          goodsId = this.options.check.map((i) => i.goodsId);
          this.checkList = goodsId;
          this.isSubmit = true;
          this.openCategory = true;
          break;
        default:
          break;
      }
    },
  },
};
</script>
