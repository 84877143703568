<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent"> </TablePage>
  </div>
</template>
<script>
import TablePage from "@/components/tablePage"; //表格页面组件
import { listPage, getOrderDetail } from "@/api/O2OThird/orderMealWxa/order"; // 点餐订单
export default {
  name: "orderList",
  components: {
    TablePage,
  },
  data() {
    return {
      options: {
        loading: false,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        title: "点餐订单",
        listNo: true, // 序号
        // exportOption: {
        //   show: true,
        //   ossKeyUrl: '/api/system/shop/report/sale/ranking/getExportGoodsInfo',
        //   fastExportUrl: '/api/system/shop/report/sale/ranking/export',
        //   exportName: '点餐订单'
        // },
        //搜索
        search: [
          {
            label: "订单时间",
            type: "datetimerange",
            model: "",
            noReset: true,
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b }),
          },
          {
            keyIndex: 0,
            format: "datetimerange",
            type: "timeRange",
            defaultValue: "day",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: "filters",
            tip: "门店编号/门店名称",
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "vipNos", label: "会员卡号" },
              { filter: "billNos", label: "销售小票号" },
              { filter: "shopNos", label: "门店编码" },
              { filter: "shopNames", label: "门店名称" },
              { filter: "goodsNos", label: "商品编码" },
              { filter: "goodsNames", label: "商品名称" },
            ],
          },
          {
            type: "local",
            label: "就餐方式",
            model: "",
            filter: "mealTypes",
            seniorSearch: true,
            hide: () => this.options?.tabColumnType == "sales",
            option: {
              multiple: true,
              clearable: true,
              data: [
                { label: "正常 ", value: 0 },
                { label: "堂食 ", value: 1 },
                { label: "外带", value: 2 },
              ],
              label: "label",
              value: "value",
              filterable: true,
            },
          },
          {
            type: "local",
            label: "订单状态",
            model: "",
            filter: "billStatuss",
            seniorSearch: true,
            hide: () => this.options?.tabColumnType == "sales",
            option: {
              multiple: true,
              clearable: true,
              data: [
                { label: "已支付 ", value: 2 },
                // { label: "已提交订单", value: 2 },
              ],
              label: "label",
              value: "value",
              filterable: true,
            },
          },
          {
            type: "local",
            label: "支付方式",
            model: "",
            filter: "payModeIds",
            seniorSearch: true,
            hide: () => this.options?.tabColumnType == "sales",
            option: {
              multiple: true,
              clearable: true,
              data: [
                { label: "微信 ", value: 8 },
                { label: "会员卡", value: 3 },
              ],
              label: "label",
              value: "value",
              filterable: true,
            },
          },
          this.$select({
            key: "listShop",
            option: {
              filter: "shopIds",
              seniorSearch: true,
              hide: () => this.options?.tabColumnType == "salesMoney",
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择门店",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "shop" }),
                    },
                  },
                ],
              },
            },
          }),
          this.$select({
            key: "listGoods",
            option: {
              seniorSearch: true,
              hide: () => this.options?.tabColumnType == "salesMoney",
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择商品",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "goods" }),
                    },
                  },
                ],
              },
            },
          }),
          this.$select({
            key: "listMachine",
            option: {
              filter: "machineIds",
              seniorSearch: true,
              hide: () => this.options?.tabColumnType == "salesMoney",
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择收银机",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "machine" }),
                    },
                  },
                ],
              },
            },
          }),
          this.$select({
            key: "listCashier",
            option: {
              filter: "cashierIds",
              seniorSearch: true,
              hide: () => this.options?.tabColumnType == "salesMoney",
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择收银员",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "cashier" }),
                    },
                  },
                ],
              },
            },
          }),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        tabColumnType: "sales",
        tabsColumns: [
          {
            title: "订单列表",
            type: "sales",
            getListApi: listPage,
            uuid: "a56ceff8-fdb7-96e1-6b16-6b1ffc050295",
            // getSummaryApi: saleRankListSummaryAPI,
            // defaultBody: { rankingType: 1 },
            //表格
            columns: [
              {
                prop: "billNo",
                label: "订单编号",
                minWidth: 180,
                align: "center",
              },
              {
                prop: "billDate",
                label: "订单时间",
                minWidth: 155,
                align: "center",
              },
              {
                prop: "mealType",
                label: "就餐方式",
                minWidth: 120,
                align: "center",
                formatter: (v) => (v == 1 ? "堂食" : v == 2 ? "外带" : "正常"),
              },
              {
                prop: "billStatusName",
                label: "订单状态",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "billQty",
                label: "商品数量",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "billFactMoney",
                label: "订单金额",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "billDiscountMoney",
                label: "优惠金额",
                minWidth: 120,
                align: "center",
              },
              // {
              //   prop: "tablewareMoney",
              //   label: "餐具费用",
              //   minWidth: 100,
              //   align: "center",
              // },
              {
                prop: "billMoney",
                label: "本单合计金额",
                minWidth: 150,
                align: "center",
                sortable: true,
              },
              {
                prop: "payTypes",
                align: "center",
                label: "收款方式",
                children: () =>
                  this.options.list?.[0]?.payTypes?.map?.((x) => ({
                    ...x,
                    label: x.value,
                    prop: `payDetailList.${x.payModeId}.payModeId.payMoney`,
                    minWidth: 110,
                    summary: true,
                    getValue: (row, prop) =>
                      row.payDetailList?.find((y) => y.payModeId === x.payModeId)
                        ?.payMoney || 0,
                  })) || [],
              },
              {
                prop: "vipNo",
                label: "会员卡号",
                minWidth: 180,
                align: "center",
              },
              {
                prop: "remark",
                label: "备注",
                minWidth: 120,
                align: "center",
              },
            ],
            // summary: ["unitQty", "billMoney", "lineGain"],
          },
          {
            title: "订单详情",
            type: "salesMoney",
            getListApi: getOrderDetail,
            uuid: "7435deb5-2bc7-9341-8942-c24b7ffaabba",
            // getSummaryApi: saleRankListSummaryAPI,
            // defaultBody: { rankingType: 2 },
            //表格
            columns: [
              {
                prop: "billDate",
                label: "销售时间",
                minWidth: 155,
                align: "center",
              },
              {
                prop: "billNo",
                label: "销售小票号",
                minWidth: 180,
                align: "center",
              },
              {
                prop: "shopName",
                label: "门店",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "machineName",
                label: "收银机",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "cashierName",
                label: "收银员",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 180,
                align: "center",
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 180,
                align: "center",
              },
              {
                prop: "barcode",
                label: "条码",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "goodsSpec",
                label: "规格",
                minWidth: 100,
                align: "center",
              },
              {
                prop: "unitName",
                label: "单位",
                minWidth: 100,
                align: "center",
              },
              {
                prop: "unitPrice",
                label: "单价",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "unitQty",
                label: "数量",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "unitMoney",
                label: "销售额",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "remark",
                label: "备注",
                minWidth: 120,
                align: "center",
              },
            ],
            // summary: ["unitQty", "unitMoney", "lineGain"],
          },
        ],
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      console.log("sssss", this.options);
      switch (type) {
        default:
          break;
      }
    },
  },
};
</script>
