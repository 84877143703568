<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent"> </TablePage>
    <!-- 弹窗 -->
    <Dialog
      ref="tablePageDialog"
      :options.sync="dialogOptions"
      @handleEvent="handleEvent"
    />
  </div>
</template>

<script>
import TablePage from "@/components/tablePage"; //表格页面组件
import {
  douYinShopRestore, //恢复门店
  douYinShopList, //抖音门店分页列表
  matchTaskSubmit, //匹配抖音门店
  matchTaskQuery, //匹配抖音门店查询
  shopPoiSync, //同步门店账户
  getShopDelete, //删除抖音门店
  shopPoiSyncQuery, //门店同步查询
} from "@/api/O2OThird/douyin/shop";
import Dialog from "@/components/Dialog";
export default {
  name: "Shop",
  components: { TablePage, Dialog },
  data() {
    return {
      //弹窗配置
      dialogOptions: {
        title: "选择抖音门店",
        width: 800,
        show: false,
        type: "TreeAndTable",
        formData: this.$dialog({ key: "douYinShop" }),
      },
      options: {
        uuid: "07a371a4-6cbc-3741-db00-dc007dafc3ba",
        radioSelect: true,
        loading: false,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        title: "抖音门店",
        getListApi: douYinShopList,
        listNo: true, // 序号
        check: [], // 选中数据
        rowKey: "shopId",
        //搜索
        search: [
          {
            type: "filters",
            tip: "分店编号/分店名称",
            isRestore: true,
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "shopNos", label: "分店编号" },
              { filter: "shopNames", label: "分店名称" },
            ],
          },
          {
            type: "button",
            tip: "查询",
            btnType: "primary",
            click: "search",
            isRestore: true,
          },
        ],
        //按钮
        buttons: [
          {
            click: "add",
            label: "新增",
            icon: "el-icon-plus",
            type: "primary",
          },
          {
            click: "matchTiktokStores",
            label: "匹配抖音门店",
            type: "primary",
          },
          {
            click: "matchingResultQuery",
            label: "匹配结果查询",
            type: "primary",
          },
          {
            click: "synchronousStoreAccount",
            label: "同步门店账户",
            type: "primary",
          },
          {
            click: "storeSynchronousQuery",
            label: "门店同步查询",
            type: "primary",
          },
          {
            click: "del",
            label: "删除",
            type: "danger",
            icon: "el-icon-delete",
            alertText: "确认要删除选中的门店吗？",
            btnType: "dropdown",
            trigger: "click", // 要求
            other: [
              {
                label: "回收站",
                collectionDialog: {
                  restoreListApi: douYinShopRestore,
                  dcollectionBody: {
                    delFlag: true,
                  },
                },
              },
            ],
          },
          {
            click: "refresh",
            right: true,
            label: "刷新",
            icon: "el-icon-refresh",
            type: "",
          },
        ],
        //表格
        columns: [
          {
            prop: "shopNo",
            label: "分店编号",
            type: "link",
            click: "update",
            minWidth: 150,
            align: "center",
          },
          {
            prop: "shopName",
            label: "分店名称",
            minWidth: 150,
            align: "center",
          },
          {
            type: "icons",
            prop: "isMatchDouyinShop",
            label: "是否抖音门店",
            minWidth: 120,
            align: "center",
            formatter: (propValue) => ({
              icon: propValue ? "el-icon-check" : "el-icon-close",
              style: `color: ${
                propValue ? "#41bb41" : "#ff4949"
              } ; font-size: 20px; font-weight: 700;`,
            }),
          },
          {
            prop: "poiName",
            label: "抖音门店名称",
            minWidth: 150,
            align: "center",
          },
          {
            prop: "poiAddress",
            label: "抖音门店地址",
            minWidth: 180,
            align: "center",
          },
          {
            prop: "longitude",
            label: "门店经度",
            minWidth: 150,
            align: "center",
          },
          {
            prop: "latitude",
            label: "门店纬度",
            minWidth: 150,
            align: "center",
          },
          {
            type: "icons",
            prop: "isMatchDouyinShop",
            label: "是否匹配",
            minWidth: 120,
            align: "center",
            formatter: (propValue) => ({
              icon: propValue ? "el-icon-check" : "el-icon-close",
              style: `color: ${
                propValue ? "#41bb41" : "#ff4949"
              } ; font-size: 20px; font-weight: 700;`,
            }),
          },
          {
            type: "icons",
            prop: "isMatchDouyinShop",
            label: "是否同步门店",
            minWidth: 120,
            align: "center",
            formatter: (propValue) => ({
              icon: propValue ? "el-icon-check" : "el-icon-close",
              style: `color: ${
                propValue ? "#41bb41" : "#ff4949"
              } ; font-size: 20px; font-weight: 700;`,
            }),
          },
          {
            prop: "remark",
            label: "备注",
            minWidth: 110,
            align: "center",
          },
          {
            prop: "updateBy",
            label: "修改人",
            minWidth: 150,
            align: "center",
          },
          {
            prop: "updateTime",
            label: "修改时间",
            minWidth: 160,
            align: "center",
          },
          {
            prop: "createBy",
            label: "创建人",
            minWidth: 150,
            align: "center",
          },
          {
            prop: "createTime",
            label: "创建时间",
            minWidth: 160,
            align: "center",
          },
        ],
        list: [],
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case "add":
          const timestampId = Date.now();
          //跳转商品明细页
          this.$router.push({
            path: "/O2OThird/douyin/shop/douyinShopDetail",
            query: {
              type: "add",
              timestampId,
            },
          });
          break;
        case "matchTiktokStores":
          this.dialogOptions = {
            title: "选择抖音门店",
            width: 800,
            show: true,
            type: "TreeAndTable",
            formData: this.$dialog({ key: "douYinShop" }),
          };
          break;
        case "matchingResultQuery":
          try {
            this.options.loading = true;
            await matchTaskQuery(this.options.radioObject.shopId);
            this.$refs.tablePage.getList();
            this.$message.success("匹配结果查询成功");
          } catch (error) {
          } finally {
            this.options.loading = false;
          }
          break;
        case "synchronousStoreAccount":
          try {
            this.options.loading = true;
            await shopPoiSync(this.options.radioObject.shopId);
            this.$refs.tablePage.getList();
            this.$message.success("同步门店账户成功");
          } catch (error) {
          } finally {
            this.options.loading = false;
          }
          break;
        case "storeSynchronousQuery":
          try {
            this.options.loading = true;
            await shopPoiSyncQuery({
              shopId: this.options.radioObject.shopId,
              type: 2,
            });
            this.$refs.tablePage.getList();
            this.$message.success("门店同步查询成功");
          } catch (error) {
          } finally {
            this.options.loading = false;
          }
          break;
        case "update":
          this.$router.push({
            path: "/O2OThird/douyin/shop/douyinShopDetail",
            query: {
              shopId: row.shopId,
              type: "Update",
            },
          });
          break;
        case "del":
          try {
            this.options.loading = true;
            await getShopDelete([this.options.radioObject.shopId]);
            this.$refs.tablePage.getList();
            this.$message.success("删除成功");
          } catch (error) {
          } finally {
            this.options.loading = false;
          }
          break;
        case "dialogChange":
          try {
            this.options.loading = true;
            await matchTaskSubmit({
              shopId: this.options.radioObject.shopId,
              poiId: this.dialogOptions.formData.table.check[0].poiId,
              latitude: this.dialogOptions.formData.table.check[0].latitude,
              longitude: this.dialogOptions.formData.table.check[0].longitude,
              address: this.dialogOptions.formData.table.check[0].address,
              poiName: this.dialogOptions.formData.table.check[0].poiName,
            });
            this.$refs.tablePage.getList();
            this.$message.success("匹配抖音门店成功");
          } catch (error) {
          } finally {
            this.options.loading = false;
          }
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
